.Teaser {
  --alignItems: start;
  --columnsCount: 1;
  --gap: 30px;
  --gridTemplateAreas: "image" "text";
  --justifyContent: start;
  --titleFontSize: 18px;

  align-items: var(--alignItems);
  display: grid;
  gap: var(--gap);
  grid-template-areas: var(--gridTemplateAreas);
  grid-template-columns: repeat(var(--columnsCount), 1fr);
  justify-content: var(--justifyContent);

  &--desktop {
    --alignItems: center;
    --columnsCount: 2;
    --gap: 40px;
    --gridTemplateAreas: "text image";
    --justifyContent: space-between;
    --titleFontSize: 24px;

    &[data-reverse-desktop] {
      --gridTemplateAreas: "image text";
    }
  }

  &--withFootnote {
    padding-bottom: 25px;
  }

  &--desktop.Teaser--withFootnote {
    padding-bottom: 50px;
  }

  &-textBlock {
    display: flex;
    flex-direction: column;
    gap: 25px;
    grid-area: text;
  }

  &-title {
    font-size: var(--titleFontSize);
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1;
    text-transform: uppercase;
  }

  &-text {
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3671;
  }

  &-footnote {
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: 11px;
    font-weight: 400;
    line-height: 1.3636;
    margin-top: 25px;
  }

  &-imageBlock {
    grid-area: image;
  }

  &-image {
    max-width: 100%;
    width: auto;
  }
}
