.KiteCupContent {
  --contentPaddingBottom: 80px;
  --contentPaddingTop: 30px;
  --contentPaddingX: 26px;
  --dividerDisplay: none;

  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--desktop {
    --contentPaddingBottom: 60px;
    --contentPaddingTop: 60px;
    --contentPaddingX: 60px;
    --dividerDisplay: block;
  }

  &-contentBlock {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    max-width: var(--container-maxWidth);
    padding: var(--contentPaddingTop) var(--contentPaddingX) var(--contentPaddingBottom);
    width: 100%;

    &[data-padding-small] {
      padding-bottom: var(--contentPaddingTop);
    }
  }

  &-darkSection {
    align-items: center;
    align-self: stretch;
    background-color: var(--color--darkCoolGrey);
    color: var(--color--lightWarmGrey25);
    display: flex;
    flex-direction: column;
  }

  &-jumboTextBlock {
    --TextBlock-gap--desktop: 30px;
    --TextBlock-gap--mobile: 30px;
    --TextBlock-titleFontSize--desktop: 38px;
    --TextBlock-titleFontSize--mobile: 26px;
  }

  &--desktop &-halfWidth {
    max-width: 50%;
    padding-right: 20px;
  }

  &-dividerBlock {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    max-width: var(--container-maxWidth);
    padding: 0 var(--contentPaddingX);
    width: 100%;
  }

  &-divider {
    background-color: var(--color--main2);
    border: none;
    display: var(--dividerDisplay);
    height: 1px;
    margin: 0;
    width: 100%;
  }
}
