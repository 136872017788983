.EventPage {
  --contentPaddingBottom: 80px;
  --contentPaddingTop: 30px;
  --contentPaddingX: 26px;

  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;

  &--desktop {
    --contentPaddingBottom: 60px;
    --contentPaddingTop: 60px;
    --contentPaddingX: 60px;
  }

  &-contentBlock {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    max-width: var(--container-maxWidth);
    padding: var(--contentPaddingTop) var(--contentPaddingX) var(--contentPaddingBottom);
    width: 100%;

    &[data-padding-small] {
      padding-bottom: var(--contentPaddingTop);
    }
  }

  &-darkSection,
  &-lightGreySection {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }

  &-darkSection {
    background-color: var(--color--darkCoolGrey);
    color: var(--color--lightWarmGrey25);
  }

  &-lightGreySection {
    background-color: var(--color--extraLightGrey);
  }
}
