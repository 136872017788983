.ProgramSelectorDesktop {
  --TextBlock-textMaxWidth: 648px;
  --Button-backgroundColor: var(--color--defenderBlue);
  --Button-color: var(--color--white);
  --itemTextDisplay: none;

  align-items: stretch;
  color: var(--color--defenderBlue);
  display: flex;
  flex-direction: column;
  gap: 40px;

  &-main {
    align-items: flex-start;
    display: flex;
    gap: 60px;
  }

  &-image {
    aspect-ratio: 880 / 532;
    max-width: 100%;
  }

  &-list {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 25px;
    width: 400px;
  }

  &-item {
    align-items: flex-start;
    display: flex;
    gap: 16px;

    &[data-active] {
      --itemTextDisplay: flex;
    }
  }

  &-item[data-active] + &-item {
    margin-top: 55px;
  }

  &-icon {
    cursor: pointer;
    flex-shrink: 0;
    height: 26px;
    width: 26px;
  }

  &-itemContent {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-block-start: 3px;
  }

  &-title {
    cursor: pointer;
    font-family: var(--fontFamily--landRoverWeb);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1;
    text-transform: uppercase;
  }

  &-text {
    display: var(--itemTextDisplay);
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }

  &-button {
    display: var(--itemTextDisplay);
  }
}
