.Main {
  --container-maxWidth: var(--container-maxWidth--mobile);

  &--desktop {
    --container-maxWidth: var(--container-maxWidth--desktop);
  }

  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}
