.Button {
  --backgroundColor: var(--Button-backgroundColor, var(--color--lightWarmGrey25));
  --color: var(--Button-color, var(--color--defenderBlue));

  align-items: center;
  background-color: var(--backgroundColor);
  border-radius: 0;
  border: 1px solid var(--backgroundColor);
  color: var(--color);
  display: flex;
  font-size: 10px;
  font-weight: 600;
  gap: 8px;
  justify-content: center;
  letter-spacing: 0.3em;
  line-height: 1.8;
  padding: 12px 20px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: transparent;
    color: var(--backgroundColor);
  }

  &-arrow {
    height: 14px;
  }
}
