@font-face {
  font-family: 'Land Rover Web';
  src: url('LandRoverWeb-Bold.woff2') format('woff2'),
    url('LandRoverWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Land Rover Web';
  src: url('LandRoverWeb-Medium.woff2') format('woff2'),
    url('LandRoverWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Land Rover Web Demi';
  src: url('LandRoverWeb-Demi.woff2') format('woff2'),
    url('LandRoverWeb-Demi.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Land Rover Web';
  src: url('LandRoverWeb-Light.woff2') format('woff2'),
    url('LandRoverWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
