.Sustainability {
  --Button-backgroundColor: var(--color--defenderBlue);
  --Button-color: var(--color--white);
  --columnsCount: 1;
  --contentBlockBorderTopWidth: 1px;
  --contentBlockTitleFontSize: 18px;
  --contentBlocksDisplay: flex;
  --contentMaxWidth: 100%;
  --contentPaddingBlock: 30px;
  --contentPaddingInline: 26px;

  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;

  &--desktop {
    --contentBlockBorderTopWidth: 0;
    --contentBlockTitleFontSize: 24px;
    --contentBlocksDisplay: grid;
    --contentMaxWidth: var(--container-maxWidth);
    --contentPaddingBlock: 60px;
    --contentPaddingInline: 60px;

    @media print, screen and (max-width: 1040px) {
      --columnsCount: 2;
    }

    @media print, screen and (min-width: 1041px) {
      --columnsCount: 3;
    }
  }

  &-contentBlock {
    border-top: var(--contentBlockBorderTopWidth) solid var(--color--main2);
    display: var(--contentBlocksDisplay);
    flex-direction: column;
    gap: 30px;
    grid-row: span 3;
    grid-template-rows: subgrid;
    padding-block: 30px;
  }

  &-contentBlockButtonContainer {
    display: flex;
    justify-content: flex-start;
  }

  &-contentBlockText {
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }

  &-contentBlock:not(:has(&-contentBlockButton)) &-contentBlockText {
    grid-row: span 2;
  }

  &-contentBlockTitle {
    font-family: var(--fontFamily--landRoverWeb);
    font-size: var(--contentBlockTitleFontSize);
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &-contentBlocks {
    display: var(--contentBlocksDisplay);
    flex-direction: column;
    gap: 30px 40px;
    grid-template-columns: repeat(var(--columnsCount), 1fr);
    max-width: var(--container-maxWidth);
    padding: var(--contentPaddingBlock) var(--contentPaddingInline);
    width: 100%;
  }

  &-lightGreySection {
    align-items: center;
    align-self: stretch;
    background-color: var(--color--extraLightGrey);
    display: flex;
    flex-direction: column;
  }
}
