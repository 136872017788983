.Home {
  --contentMaxWidth: 100%;
  --contentPaddingBottom: 80px;
  --contentPaddingTop: 30px;
  --contentPaddingX: 26px;

  &--desktop {
    --contentMaxWidth: var(--container-maxWidth);
    --contentPaddingBottom: 60px;
    --contentPaddingTop: 60px;
    --contentPaddingX: 60px;
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  &-contentBlock {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    max-width: var(--container-maxWidth);
    padding: var(--contentPaddingTop) var(--contentPaddingX) var(--contentPaddingBottom);
    width: 100%;

    &[data-padding-small] {
      padding-bottom: var(--contentPaddingTop);
    }
  }

  &-jumboTextBlock {
    --TextBlock-gap--desktop: 30px;
    --TextBlock-gap--mobile: 30px;
    --TextBlock-titleFontSize--desktop: 38px;
    --TextBlock-titleFontSize--mobile: 26px;
  }

  &-darkSection,
  &-lightSection {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }

  &-darkSection {
    background-color: var(--color--darkCoolGrey);
    color: var(--color--lightWarmGrey25);
  }

  &-lightSection {
    --Button-backgroundColor: var(--color--defenderBlue);
    --Button-color:  var(--color--lightWarmGrey25);

    background-color: var(--color--extraLightGrey);
  }

  &--desktop &-halfWidth {
    max-width: 50%;
    padding-right: 20px;
  }
}
