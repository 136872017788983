
.Footer {
  --contentFlexDirection: column;
  --contentJustifyContent: flex-start;
  --contentPaddingBottom: 80px;
  --contentPaddingTop: 30px;
  --contentPaddingX: 26px;
  --fontSize: 10px;
  --socialLinksGap: 20px;

  align-items: center;
  color: var(--color--darkCoolGrey);
  display: flex;
  flex-direction: column;
  font-size: var(--fontSize);
  line-height: 1.4;
  width: 100%;

  &--desktop {
    --fontSize: 11px;
    --contentFlexDirection: row-reverse;
    --contentJustifyContent: space-between;
    --contentPaddingBottom: 80px;
    --contentPaddingTop: 60px;
    --contentPaddingX: 60px;
    --socialLinksGap: 40px;
  }

  &-content {
    display: flex;
    flex-direction: var(--contentFlexDirection);
    justify-content: var(--contentJustifyContent);
    gap: 32px;
    max-width: var(--container-maxWidth);
    padding: var(--contentPaddingTop) var(--contentPaddingX) var(--contentPaddingBottom);
    width: 100%;
  }

  &-socialLinks {
    display: flex;
    color: var(--color--defenderBlue);
    gap: var(--socialLinksGap);
  }

  &-socialLink {
    align-items: center;
    border: 1px solid currentcolor;
    color: inherit;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;

    &:hover,
    &:focus-visible {
      outline: 1px solid currentcolor;
      outline-offset: 0;
    }
  }

  &-socialIcon {
    max-height: 26px;
    max-width: 32px;
    width: 100%;
  }

  &-texts {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  &-textLine {
    display: flex;
    justify-content: space-between;
  }

  &-link {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-copyright,
  &-textLinks {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
  }

  &-imprint,
  &-privacy {
    color: var(--color--white);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
