@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 500;
  font-style: italic;
  src: url('AvenirNextW1G-MediumItalic.eot');
  src: url('AvenirNextW1G-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-MediumItalic.woff2') format('woff2'),
    url('AvenirNextW1G-MediumItalic.woff') format('woff'),
    url('AvenirNextW1G-MediumItalic.ttf') format('truetype'),
    url('AvenirNextW1G-MediumItalic.svg#AvenirNextW1G-MediumItalic')
      format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: bold;
  font-style: normal;
  src: url('AvenirNextW1G-Demi.eot');
  src: url('AvenirNextW1G-Demi.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Demi.woff2') format('woff2'),
    url('AvenirNextW1G-Demi.woff') format('woff'),
    url('AvenirNextW1G-Demi.ttf') format('truetype'),
    url('AvenirNextW1G-Demi.svg#AvenirNextW1G-Demi') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: bold;
  font-style: italic;
  src: url('AvenirNextW1G-BoldItalic.eot');
  src: url('AvenirNextW1G-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-BoldItalic.woff2') format('woff2'),
    url('AvenirNextW1G-BoldItalic.woff') format('woff'),
    url('AvenirNextW1G-BoldItalic.ttf') format('truetype'),
    url('AvenirNextW1G-BoldItalic.svg#AvenirNextW1G-BoldItalic') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 500;
  font-style: normal;
  src: url('AvenirNextW1G-Medium.eot');
  src: url('AvenirNextW1G-Medium.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Medium.woff2') format('woff2'),
    url('AvenirNextW1G-Medium.woff') format('woff'),
    url('AvenirNextW1G-Medium.ttf') format('truetype'),
    url('AvenirNextW1G-Medium.svg#AvenirNextW1G-Medium') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: normal;
  font-style: normal;
  src: url('AvenirNextW1G-Regular.eot');
  src: url('AvenirNextW1G-Regular.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Regular.woff2') format('woff2'),
    url('AvenirNextW1G-Regular.woff') format('woff'),
    url('AvenirNextW1G-Regular.ttf') format('truetype'),
    url('AvenirNextW1G-Regular.svg#AvenirNextW1G-Regular') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 300;
  font-style: normal;
  src: url('AvenirNextW1G-Light.eot');
  src: url('AvenirNextW1G-Light.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Light.woff2') format('woff2'),
    url('AvenirNextW1G-Light.woff') format('woff'),
    url('AvenirNextW1G-Light.ttf') format('truetype'),
    url('AvenirNextW1G-Light.svg#AvenirNextW1G-Light') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G Ultra';
  font-weight: 200;
  font-style: normal;
  src: url('AvenirNextW1G-UltraLight.eot');
  src: url('AvenirNextW1G-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-UltraLight.woff2') format('woff2'),
    url('AvenirNextW1G-UltraLight.woff') format('woff'),
    url('AvenirNextW1G-UltraLight.ttf') format('truetype'),
    url('AvenirNextW1G-UltraLight.svg#AvenirNextW1G-UltraLight') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 900;
  font-style: italic;
  src: url('AvenirNextW1G-HeavyItalic.eot');
  src: url('AvenirNextW1G-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-HeavyItalic.woff2') format('woff2'),
    url('AvenirNextW1G-HeavyItalic.woff') format('woff'),
    url('AvenirNextW1G-HeavyItalic.ttf') format('truetype'),
    url('AvenirNextW1G-HeavyItalic.svg#AvenirNextW1G-HeavyItalic') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: bold;
  font-style: italic;
  src: url('AvenirNextW1G-DemiItalic.eot');
  src: url('AvenirNextW1G-DemiItalic.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-DemiItalic.woff2') format('woff2'),
    url('AvenirNextW1G-DemiItalic.woff') format('woff'),
    url('AvenirNextW1G-DemiItalic.ttf') format('truetype'),
    url('AvenirNextW1G-DemiItalic.svg#AvenirNextW1G-DemiItalic') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: normal;
  font-style: italic;
  src: url('AvenirNextW1G-Italic.eot');
  src: url('AvenirNextW1G-Italic.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Italic.woff2') format('woff2'),
    url('AvenirNextW1G-Italic.woff') format('woff'),
    url('AvenirNextW1G-Italic.ttf') format('truetype'),
    url('AvenirNextW1G-Italic.svg#AvenirNextW1G-Italic') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 900;
  font-style: normal;
  src: url('AvenirNextW1G-Heavy.eot');
  src: url('AvenirNextW1G-Heavy.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Heavy.woff2') format('woff2'),
    url('AvenirNextW1G-Heavy.woff') format('woff'),
    url('AvenirNextW1G-Heavy.ttf') format('truetype'),
    url('AvenirNextW1G-Heavy.svg#AvenirNextW1G-Heavy') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: bold;
  font-style: normal;
  src: url('AvenirNextW1G-Bold.eot');
  src: url('AvenirNextW1G-Bold.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Bold.woff2') format('woff2'),
    url('AvenirNextW1G-Bold.woff') format('woff'),
    url('AvenirNextW1G-Bold.ttf') format('truetype'),
    url('AvenirNextW1G-Bold.svg#AvenirNextW1G-Bold') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 100;
  font-style: italic;
  src: url('AvenirNextW1G-ThinItalic.eot');
  src: url('AvenirNextW1G-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-ThinItalic.woff2') format('woff2'),
    url('AvenirNextW1G-ThinItalic.woff') format('woff'),
    url('AvenirNextW1G-ThinItalic.ttf') format('truetype'),
    url('AvenirNextW1G-ThinItalic.svg#AvenirNextW1G-ThinItalic') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G Ultra';
  font-weight: 200;
  font-style: italic;
  src: url('AvenirNextW1G-UltraLightIt.eot');
  src: url('AvenirNextW1G-UltraLightIt.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-UltraLightIt.woff2') format('woff2'),
    url('AvenirNextW1G-UltraLightIt.woff') format('woff'),
    url('AvenirNextW1G-UltraLightIt.ttf') format('truetype'),
    url('AvenirNextW1G-UltraLightIt.svg#AvenirNextW1G-UltraLightIt')
      format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 100;
  font-style: normal;
  src: url('AvenirNextW1G-Thin.eot');
  src: url('AvenirNextW1G-Thin.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-Thin.woff2') format('woff2'),
    url('AvenirNextW1G-Thin.woff') format('woff'),
    url('AvenirNextW1G-Thin.ttf') format('truetype'),
    url('AvenirNextW1G-Thin.svg#AvenirNextW1G-Thin') format('svg');
}

@font-face {
  font-family: 'Avenir Next W1G';
  font-weight: 300;
  font-style: italic;
  src: url('AvenirNextW1G-LightItalic.eot');
  src: url('AvenirNextW1G-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('AvenirNextW1G-LightItalic.woff2') format('woff2'),
    url('AvenirNextW1G-LightItalic.woff') format('woff'),
    url('AvenirNextW1G-LightItalic.ttf') format('truetype'),
    url('AvenirNextW1G-LightItalic.svg#AvenirNextW1G-LightItalic') format('svg');
}
