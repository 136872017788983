.Header {
  --contentHeight: 173px;
  --logoImageHeight: 8px;
  --logoSectionHeight: 47px;

  align-items: stretch;
  color: var(--color--black);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  &--desktop {
    --contentHeight: 676px;
    --logoImageHeight: 10px;
    --logoSectionHeight: 73px;
  }

  &-logoSection {
    align-items: center;
    background-color: var(--color--white);
    display: flex;
    flex-shrink: 0;
    height: var(--logoSectionHeight);
    justify-content: center;
  }

  &-logoImage {
    color: var(--color--black);
    display: block;
    height: var(--logoImageHeight);
  }

  &-logoImageButton {
    background-color: transparent;
    border-width: 0;
    height: fit-content;
    padding: 0;
  }

  &-imageSection {
    align-items: center;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
  }

  &-content {
    color: var(--color--lightWarmGrey25);
    display: flex;
    flex-direction: column;
    height: var(--contentHeight);
    max-width: var(--container-maxWidth);
    padding: 35px 0;
    width: 100%;
  }
}
