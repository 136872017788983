:root {
  --container-maxWidth--desktop: 1460px;
  --container-maxWidth--mobile: 100%;
  --color--white: #fff;
  --color--black: #000;

  // Main colors
  --color--defenderBlue: #0c121c;
  --color--extraLightGrey: #f8f9f9;
  --color--main1: #4a4f54;
  --color--main2: #a7b2bb;
  --color--main3: #e0e5e8;
  --color--main4: #eff3f5;
  --color--main5: #fff;

  // Warm secondary colors
  --color--darkWarmGrey: #5c5652;
  --color--midWarmGrey: #989991;
  --color--lightWarmGrey: #eae8e4;
  --color--lightWarmGrey25: #fff;

  // Cool secondary colors
  --color--darkCoolGrey: #4a4f54;
  --color--midCoolGrey: #b0b7bb;
  --color--lightCoolGrey: #e4e8e8;
  --color--lightCoolGrey25: #f3f5f5;

  // Font sizes
  --fontSize--xxxs: 12px;
  --fontSize--xxs: 14px;
  --fontSize--xs: 15px;
  --fontSize--s: 18px;
  --fontSize--m: 20px;
  --fontSize--xl: 26px;
  --fontSize--xxl: 36px;

  // Font families
  --fontFamily--landRoverWeb: 'Land Rover Web', 'Arial', 'Helvetica', sans-serif;
  --fontFamily--avenirNextW1G: 'Avenir Next W1G', 'Arial', 'Helvetica', sans-serif;
}
