.EventRegistration {
  --contentFontSize: 10px;
  --contentLetterSpacing: 0.3em;
  --contentLineHeight: 1.8;
  --titleFontSize: 26px;

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &--desktop {
    --contentFontSize: 12px;
    --contentLetterSpacing: 0.25em;
    --contentLineHeight: 1.5;
    --titleFontSize: 38px;
  }

  &-title {
    font-family: var(--fontFamily--landRoverWeb);
    font-size: var(--titleFontSize);
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1;
    text-transform: uppercase;
  }

  &-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: var(--contentFontSize);
    letter-spacing: var(--contentLetterSpacing);
    line-height: var(--contentLineHeight);
    text-transform: uppercase;
  }

  &-field {
    display: flex;
    gap: 1ch;
  }

  &-fieldName {
    font-weight: 600;
  }
}
