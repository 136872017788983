html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: var(--fontFamily--landRoverWeb);
  background-color: var(--color--lightWarmGrey25);
  line-height: 1.5;
}

html,
body {
  margin: 0;
  align-items: center;
  width: 100%;
}

html,
body,
.Root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Root {
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

svg {
  fill: currentcolor;
}

button {
  cursor: pointer;
  font-family: inherit;
}
