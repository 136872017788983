.ProgramSelectorMobile {
  --Button-backgroundColor: var(--color--defenderBlue);
  --Button-color: var(--color--white);

  align-items: flex-start;
  color: var(--color--defenderBlue);
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;

  &-swiper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
  }

  &-slide {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-pagination {
    display: flex;
    gap: 22px;
  }

  &-paginationItem {
    border-radius: 50%;
    border: 1px solid currentcolor;
    display: block;
    height: 12px;
    width: 12px;

    &--active {
      background-color: currentcolor;
    }
  }

  &-image {
    aspect-ratio: 323 / 195;
    width: 100%;
  }
}
