.EventInstructor {
  --alignItems: flex-start;
  --flexDirection: column;
  --gap: 26px;
  --imageMaxWidth: 149px;
  --nameFontSize: 18px;
  --subtitleFontFamily: var(--fontFamily--avenirNextW1G);
  --subtitleFontSize: 14px;
  --subtitleFontWeight: 275;
  --subtitleLineHeight: 1.2857;
  --subtitleLetterSpacing: 0.03em;

  align-items: var(--alignItems);
  display: flex;
  flex-direction: var(--flexDirection);
  gap: var(--gap);

  &--desktop {
    --alignItems: center;
    --flexDirection: row;
    --gap: 46px;
    --imageMaxWidth: 159px;
    --nameFontSize: 24px;
    --subtitleFontFamily: var(--fontFamily--landRoverWeb);
    --subtitleFontSize: 18px;
    --subtitleFontWeight: 300;
    --subtitleLineHeight: 1;
    --subtitleLetterSpacing: 1.2px;
  }

  &-image {
    border-radius: 50%;
    max-width: var(--imageMaxWidth);
  }

  &-info {
    font-family: var(--fontFamily--landRoverWeb);
    line-height: 1;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &-subtitle {
    font-family: var(--subtitleFontFamily);
    font-size: var(--subtitleFontSize);
    font-weight: var(--subtitleFontWeight);
    letter-spacing: var(--subtitleLetterSpacing);
    line-height: var(--subtitleLineHeight);
  }

  &-name {
    font-family: var(--fontFamily--landRoverWeb);
    font-size: var(--nameFontSize);
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1;
  }

  &-description {
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }

  &-rightBlock {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &-topBlock {
    display: flex;
    align-items: center;
    gap: 26px;
  }
}
