.LocationsMap {
  --TextBlock-textMaxWidth: 508px;
  --gridColumns: 1;

  align-items: center;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  color: var(--color--defenderBlue);
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;
  width: 100%;

  &--desktop {
    --gridColumns: 2;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(var(--gridColumns), 1fr);
    align-items: center;
    max-width: var(--container-maxWidth);
    width: 100%;
    gap: 30px;
  }

  &-animationMap {
    width: 100%;
    max-width: 322px;
    min-height: 622px;
    position: relative;
    overflow: hidden;
  }

  &-lottie {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &-animation {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-pageLink {
    width: 124px;
    height: 70px;
    position: absolute;
    background-color: transparent;

    &:first-of-type {
      height: 80px;
    }
  }
}
