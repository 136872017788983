.KiteCupRichSection {
  --alignItems: stretch;
  --flexDirection: column-reverse;
  --gap: 0;
  --paddingX: 0;
  --paddingY: 0;
  --titleFontSize: 16px;
  --titleLetterSpacing: 0.9px;
  --titleLineHeight: 1.125;
  --subtitleFontSize: 18px;

  align-items: var(--alignItems);
  display: flex;
  flex-direction: var(--flexDirection);
  gap: var(--gap);
  padding: var(--paddingY) var(--paddingX);

  &--desktop {
    --alignItems: center;
    --flexDirection: row;
    --gap: 154px;
    --paddingX: 60px;
    --paddingY: 50px;
    --titleFontSize: 18px;
    --titleLetterSpacing: 1.2px;
    --titleLineHeight: 1;
    --subtitleFontSize: 24px;
  }

  &-image {
    width: 100%;
  }

  &-textBlock {
    align-items: stretch;
    display: flex;
    max-width: 534px;
    padding: 50px 25px;
  }

  &--desktop &-imageBlock,
  &--desktop &-textBlock {
    flex: 1;
  }

  &--desktop &-textBlock {
    padding: 0;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &-contentBody {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;
  }

  &-contentSpacer {
    display: none;
  }

  &--desktop &-contentSpacer {
    display: block;
    width: 114px;
  }

  &-title {
    font-family: var(--fontFamily--landRoverWeb);
    font-size: var(--titleFontSize);
    font-weight: 300;
    letter-spacing: var(--titleLetterSpacing);
    line-height: var(--titleLineHeight);
    text-transform: uppercase;
  }

  &-subtitle {
    font-family: var(--fontFamily--landRoverWeb);
    font-size: var(--subtitleFontSize);
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1;
    text-transform: uppercase;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &-gridContent {
    display: grid;
    grid-template-columns: 35px 1fr;
  }

  &-content,
  &-gridContent {
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }

  &--desktop &-textWidthLimiter {
    max-width: 535px;
  }

  &--desktop &-title,
  &--desktop &-text {
    flex: 1;
  }
}
