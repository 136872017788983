.EventInfo {
  --TextBlock-textMaxWidth: 533px;
  --alignItems: flex-start;
  --dividerHeight: 1px;
  --dividerWidth: auto;
  --flexDirection: column;
  --gap: 50px;

  align-items: var(--alignItems);
  display: flex;
  flex-direction: var(--flexDirection);
  gap: var(--gap);

  &--desktop {
    --alignItems: center;
    --dividerHeight: auto;
    --dividerWidth: 1px;
    --flexDirection: row;
    --gap: 40px;
  }

  &-description,
  &-instructor,
  &-spacer {
    flex: 1;
  }

  &-divider {
    align-self: stretch;
    background-color: var(--color--main2);
    height: var(--dividerHeight);
    width: var(--dividerWidth);
  }
}
