.TextBlock {
  --gap--desktop: var(--TextBlock-gap--desktop, var(--TextBlock-gap, 30px));
  --gap--mobile: var(--TextBlock-gap--mobile, var(--TextBlock-gap, 18px));
  --gap: var(--gap--mobile);
  --textMaxWidth: var(--TextBlock-textMaxWidth, initial);
  --titleFontSize--desktop: var(--TextBlock-titleFontSize--desktop, var(--TextBlock-titleFontSize, 24px));
  --titleFontSize--mobile: var(--TextBlock-titleFontSize--mobile, var(--TextBlock-titleFontSize, 18px));
  --titleFontSize: var(--titleFontSize--mobile);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  &--desktop {
    --gap: var(--gap--desktop);
    --titleFontSize: var(--titleFontSize--desktop);
  }

  &-header {
    display: flex;
    flex-direction: column;
    font-family: var(--fontFamily--landRoverWeb);
    gap: 5px;
    line-height: 1;
    text-transform: uppercase;
  }

  &-subtitle {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.03em;
  }

  &-title {
    font-size: var(--titleFontSize);
    font-weight: 700;
    letter-spacing: 0.05em;
  }

  &-body,
  &-collapsible {
    display: flex;
    flex-direction: column;
    font-family: var(--fontFamily--avenirNextW1G);
    font-size: 14px;
    font-weight: 400;
    gap: 19px;
    line-height: 1.5;
    max-width: var(--textMaxWidth);
  }

  &-toggleButton {
    align-items: center;
    background-color: transparent;
    border: 0 none;
    color: currentcolor;
    display: flex;
    font-family: var(--fontFamily--landRoverWeb);
    font-size: 15px;
    font-weight: 500;
    gap: 10px;
    line-height: 1;
    outline: none;
    padding: 0;
    text-transform: uppercase;
  }

  &-arrow {
    height: 14px;
    transform: rotate(90deg);
    transition: transform 0.3s ease-out;
  }

  &--expanded &-arrow {
    transform: rotate(-90deg);
  }
}
